import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, child, get } from "firebase/database";
import {useNavigate, Link} from "react-router-dom";


function Play(props){
    const {id, name, date, cur, foto, max, place, maxRes} = props;

    const navigate = useNavigate();

    return(
        <Link to={`/${name}`} state={props} key={id} className={"Play"} >
            <div className={"info"} >
                <div className={"Header"} >{name}</div>
                <div>{date}</div>
                <div>{place}</div>
            </div>
            <span className="tooltiptext">Rezervovat</span>
        </Link >
    )
}

export default Play;