import React from "react";
import {Link, useNavigate} from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import UserData from "../components/UserData";
import { createContext, useContext, useState } from 'react';

function Login(){
    const auth = getAuth();

    const navigate = useNavigate();
    const user = useContext(UserData);

    function Login(){
        const mail = document.getElementById("username").value;
        const pass = document.getElementById("pass").value;

        signInWithEmailAndPassword(auth, mail,pass )
            .then((userCredential) => {
                // Signed in
                user.user = mail;
                localStorage.setItem('user', user.user)
                navigate('/');
                // ...
            })
            .catch((error) => {
                alert ("Chyba, chybně zadané údaje!");
            });
    }

    return(
        <div className="Log" key={"Login"}>
            <h1>Divadelní ateliér Ulita - rezervace</h1>
            <div className={"Login"} >
                <input type="text" name="username" id="username" required placeholder="Email"/>
                <input type="password" name="pass" id="pass" required placeholder="Heslo"/>
                <button onClick={Login} className="submit" name="submit">Přihlásit</button>
                <Link className={"regis"} to={`/register`}>Registrovat</Link>
                <div className={"regis"}>Email: novakp@ulita.cz</div>
            </div>

        </div>
    );
}

export default Login;