import React from "react";
import Play from "../components/Play";


function Home(props){
    const {data} = props;
    return(
        <div className={"ListOfPlays"} key={"Home"}>{
                data.map((data) => (
                    <Play  id={data.id}
                           name={data.name}
                           date={data.date}
                           cur={data.cur}
                           max={data.max}
                           foto={data.foto}
                            place={data.place}
                            maxRes={data.maxRes}/>
                ))
            }
        </div>
    );
}

export default Home;