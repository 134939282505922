import './App.css';
import { collection, getDocs, query, where } from "firebase/firestore";
import { initializeApp  } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import React, {useState, useEffect, useContext} from "react"
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route, useNavigate, Link} from "react-router-dom";
import PlayPage from "./pages/PlayPage";
import Home from "./pages/Home";
import Login from "./pages/Login";
import UserData from "./components/UserData";
import Tickets from "./pages/Tickets";
import Register from "./pages/Register";


function App() {
    const navigate = useNavigate();

    const firebaseConfig = {
        apiKey: "AIzaSyDgUWfuk2rRuqZaXBZBvCfM9hNuK30DRxc",
        authDomain: "reservationsystem-3b8bf.firebaseapp.com",
        projectId: "reservationsystem-3b8bf",
        storageBucket: "reservationsystem-3b8bf.appspot.com",
        messagingSenderId: "44932151558",
        appId: "1:44932151558:web:8f7edd0c0a17bcf2724687",
        measurementId: "G-CJFH1WLBXK"
    };
    const app = initializeApp(firebaseConfig);

  let [data, setData] = useState([]);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const user = useContext(UserData);
  let [open, setOpen] = useState(false);


  useEffect( () => {

      const loggedInUser = localStorage.getItem("user");

      if (user.user != '') {
          navigate('/');
      }
      else if(loggedInUser){
          user.user = loggedInUser;
          navigate('/');
      }
      else {
          navigate('/login');
      }

      async function fetchData() {
      try {
        const q = await getDocs(collection(db, "plays"))
            .then(q => {
              //console.log(q);
              q.forEach(element => {
                var ata = element.data();
                setData(arr => [...arr, ata]);
              })
            });

      } catch (err) {
        console.log(err);
      }
    }
    fetchData()
        .then(() => console.log())
  }, []);

  function LogOff(){
      user.user = '';
      setOpen(false);
      localStorage.setItem('user', '')
      window.location.reload(false)

  }

  if(data.length < 1){
    return (
        <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
  }
  else {
    return (
        <div className="App">
            {user.user != '' && open === false &&
                <div>
                    <input type="checkbox" id="menu_checkbox" />
                    <label htmlFor="menu_checkbox" onClick={() => setOpen(!open)}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </label>
                </div>
            }
            {user.user != '' && open === true &&
            <div className={"SideBar"} display={"none"}>
                <div className={"TopBar"}>
                    <span className="Close" onClick={() => setOpen(false)}></span>
                    <div className={"UserName"}>{user.user}</div>
                    <Link to={'/'} onClick={() => {setOpen(false)  }} className={"TopBarButton"} >Akce</Link>
                    <Link to={'/tickets'} onClick={() => {setOpen(false) }} className={"TopBarButton"}>Lístky</Link>
                    <Link className={"TopBarButton"} onClick={() => {setOpen(false); LogOff() }}>Odhlásit</Link>
                    <img className={"Logo"} src="https://firebasestorage.googleapis.com/v0/b/reservationsystem-3b8bf.appspot.com/o/logo_divadlo-02-7.jpg?alt=media&token=d37e3902-4034-4a6e-837f-03cf10498442" alt=""/>
                </div>
                <div className={"Blank"}></div>
            </div>

            }
            <Routes>
              <Route path="/" element={<Home data={data}/>} />
              <Route path={'/:id'} exact element={<PlayPage/>} />
              <Route path={'/tickets'}  element={<Tickets />} />
              <Route path="/login" element={<Login/>} />
                <Route path="/register" element={<Register/>} />
          </Routes>
        </div>
    );
  }
}

export default App;


