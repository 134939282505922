import QRCode from 'react-qr-code';
import React, {useContext, useEffect, useState} from "react";
import {collection, getDocs, getFirestore, query, where} from "firebase/firestore";
import UserData from "../components/UserData";
import {useNavigate} from "react-router-dom";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';



function Tickets(){

    let [tickets, setTickets] = useState([]);
    const db = getFirestore();
    const user = useContext(UserData);
    const navigate = useNavigate();

    useEffect( () => {
        async function fetchData() {
            try {
                const ticketsRef = collection(db, "Tickets");
                const d = query(ticketsRef, where("user", "==", user.user));
                const querySnapshot = await getDocs(d);
                querySnapshot.forEach((doc) => {
                    setTickets(arr => [...arr, doc]);
                });

            } catch (err) {
                ;
            }
        }
        fetchData()
    }, []);

    function Back(){
        navigate('/');
    }

    function downloadPdfDocument(props){
        const rootElementId = props;
        const input = document.getElementById(rootElementId);
        html2canvas(input)
            .then((canvas) => {
                const a = document.createElement("a");
                a.href = canvas.toDataURL('image/png');
                a.download =rootElementId +"_ticket.png"
                a.click()
            })
    }

    return(
        <div className={"Top"} key={"Ticket"} >
            <div className={"PageT"}>
                <div className={"PlayHeader"}>Lístky</div>
                <div className={"ListOfTickets"} key={"Home"}>
                    {
                        tickets.map((t) => (
                            <div>
                                <div className={"Ticket"} id={t.id}>
                                    <div className={"Header"}>{t.data().title}</div>
                                    <QRCode
                                        title="DAU_Ticket"
                                        value={"DAU_"+t.id}
                                        bgColor={"white"}
                                        fgColor={"black"}
                                        size={100}
                                    />
                                    <div>{t.data().date}</div>
                                </div>
                                <button className={"submit"} onClick={() => downloadPdfDocument(t.id)}>Stáhnout</button>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    );
}

export default Tickets;