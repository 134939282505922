import React from "react";
import {Link, useNavigate} from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import UserData from "../components/UserData";
import { createContext, useContext, useState } from 'react';

function Register(){

    const auth = getAuth();

    const navigate = useNavigate();
    const user = useContext(UserData);

    function Register(){
        const mail = document.getElementById("username").value;
        const pass = document.getElementById("pass").value;
        const pass2 = document.getElementById("pass2").value;

        if(pass === pass2){
            createUserWithEmailAndPassword(auth, mail, pass)
                .then((userCredential) => {
                    // Signed in
                    user.user = mail;
                    localStorage.setItem('user', user.user)
                    navigate('/');
                })
                .catch((error) => {
                    alert ("Chyba");
                });
        }
        else{
            alert ("Chyba, zadaná hesla se neshodují");
        }
    }

    return(
        <div className="Log" key={"Register"}>
            <h1>Divadelní ateliér Ulita - rezervace</h1>
            <div className={"Login"} >
                <input type="email" name = "username" id = "username" required placeholder="Email"/>
                <input type="password" name = "pass" id = "pass" required placeholder="Heslo - minimálně 6 znaků"/>
                <input type="password" name = "pass2" id = "pass2" required placeholder="Znovu heslo"/>
                <button onClick={Register} className="submit" >Registrovat</button>
                <Link className={"regis"} to={`/login`}>Přihlásit</Link>
            </div>

        </div>
    );
}

export default Register;