import {useLocation, useNavigate} from 'react-router-dom'
import React, {useContext, useEffect, useState} from "react";
import {collection, getDocs, query, doc,updateDoc,addDoc, where, getCountFromServer} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import UserData from "../components/UserData";
import OnImagesLoaded from 'react-on-images-loaded';

function PlayPage(props){

    const location = useLocation()
    const {id, name, date, cur, foto, max, maxRes} = location.state;
    const navigate = useNavigate();
    const db = getFirestore();
    const user = useContext(UserData);

    const [playMax, setPlayMax] = useState();
    const [hasTicket, setHasTicket] = useState(false);

    useEffect( () => {
        async function fetchData() {
            const ticketsRef = collection(db, "Tickets");
            const d = query(ticketsRef, where("user", "==", user.user), where("title", "==", name));
            const querySnapshot = await getCountFromServer(d);
            var numer = querySnapshot.data().count
            if(numer < 1){
                setHasTicket(false)
            }
            else{
                setHasTicket(true)
            }
        }
        function setMax(){
            if(max-cur <5){
                setPlayMax(max-cur);
            }
            else{
                setPlayMax(maxRes)
            }
        }
        fetchData()
            .then(() => setMax())
        }, []);

    function Back(){
        navigate('/');
    }

    async function Reserve(){
        const num = +document.getElementById("num").value
        if(num <= playMax && num>0 && Number.isInteger(num) ){
            const docRef = addDoc(collection(db, "Tickets"), {
                title:name, date: date, user: user.user, number: num, checked: false
            });
            const userRef = collection(db, "plays");
            const d = query(userRef, where("name", "==", name));
            const querySnapshot = await getDocs(d);
            querySnapshot.forEach((doce) => {
                const userRef = doc(db, "plays", doce.id);
                updateDoc(userRef, {
                    "cur": cur + num
                })
                    .then(alert ("Děkujeme za rezervaci. Váš lístek najdete ve složce lístky"))
                    .then(navigate('/'))
            });
        }
        else{
            alert ("Chyba v počtu rezervací");
        }
    }

    return(
        <div key={"Page"} className={"Top"}>
            <img className={"image"} src={foto} alt=""/>
            <div className={"ErrorMessage"} id={"ErrorMessage"}></div>
            <div className={"Page"}>
                <div className={"PlayHeader"}>{name}</div>
                <div className={"infoPage"}>
                    <div>Datum a čas: {date}</div>
                    <div>Zbývající počet míst: {max-cur}</div>
                    <div>Rezervace:</div>
                    {max-cur <1 && hasTicket === false &&
                    <div>Omlouváme se, ale na akci již není místo</div>
                    }
                    {hasTicket === true &&
                    <div>Omlouváme se, ale na akci lze mít pouze jednu rezervaci</div>
                    }

                </div>
                {max-cur >0 && hasTicket === false &&
                    <div className={"form"}>
                        <div className={"num"}>
                            <div className={"num"}>Počet lidí:</div>
                            <input type="number" id="num" required  min={0} max={playMax}/>
                        </div>
                        <button className={"submit"} onClick={Reserve}>Rezervovat</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default PlayPage;